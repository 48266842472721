exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-bungalow-js": () => import("./../../../src/templates/bungalow.js" /* webpackChunkName: "component---src-templates-bungalow-js" */),
  "component---src-templates-career-js": () => import("./../../../src/templates/career.js" /* webpackChunkName: "component---src-templates-career-js" */),
  "component---src-templates-celebration-js": () => import("./../../../src/templates/celebration.js" /* webpackChunkName: "component---src-templates-celebration-js" */),
  "component---src-templates-destination-js": () => import("./../../../src/templates/destination.js" /* webpackChunkName: "component---src-templates-destination-js" */),
  "component---src-templates-enquiry-js": () => import("./../../../src/templates/enquiry.js" /* webpackChunkName: "component---src-templates-enquiry-js" */),
  "component---src-templates-experience-js": () => import("./../../../src/templates/experience.js" /* webpackChunkName: "component---src-templates-experience-js" */),
  "component---src-templates-journey-js": () => import("./../../../src/templates/journey.js" /* webpackChunkName: "component---src-templates-journey-js" */),
  "component---src-templates-offer-js": () => import("./../../../src/templates/offer.js" /* webpackChunkName: "component---src-templates-offer-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-resort-js": () => import("./../../../src/templates/resort.js" /* webpackChunkName: "component---src-templates-resort-js" */),
  "component---src-templates-thankyou-js": () => import("./../../../src/templates/thankyou.js" /* webpackChunkName: "component---src-templates-thankyou-js" */),
  "component---src-templates-travelstyle-js": () => import("./../../../src/templates/travelstyle.js" /* webpackChunkName: "component---src-templates-travelstyle-js" */)
}

